<template>
  <v-sheet>
    <v-row>
      <v-col :md="colMd">
        <div class="message-content h-100" style="height: 100%">
          <div
            class="d-flex justify-content-end"
            v-if="
              relatedType != 'customer-remark-notes' &&
              relatedType != 'lead-remark-notes'
            "
          >
            <v-row>
              <v-col class="col-md-4"></v-col>
              <v-col class="col-md-4"> </v-col>
              <v-col class="col-md-4 d-flex">
                <v-text-field
                  v-model.trim="DataSearch"
                  clearable
                  dense
                  filled
                  flat
                  v-on:click:clear="resetSearch"
                  label="Search"
                  hide-details
                  solo
                  :loading="pageLoading"
                  :disabled="pageLoading"
                  color="cyan"
                  @keydown.esc="onEsc"
                  @keydown.enter="getNotesRemark"
                >
                  <template #prepend-inner>
                    <v-icon color="grey" class="ml-2 mr-2">
                      mdi-magnify
                    </v-icon>
                  </template>
                </v-text-field>

                <v-btn
                  depressed
                  color="white"
                  tile
                  text
                  small
                  style="height: 30px; background-color: #00bcd4"
                  v-on:click="getNotesRemark"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
                <v-icon
                  v-if="documentTypes == 25"
                  color="cyan"
                  @click="
                    reminderList_dialog = true;
                    colMd = 6;
                  "
                  style="margin-top: -28px; font-size: 37px"
                  >mdi-dots-vertical</v-icon
                >
                <!-- <v-btn
									depressed
									color="white"
									class="red darken-4 ml-2"
									tile
									text
									small
									style="height: 30px; min-width: 0px !important"
									v-on:click="resetSearch"
								>
									<v-icon>mdi-close</v-icon>
								</v-btn> -->
              </v-col>
            </v-row>
          </div>
          <div class="inbox">
            <span class="divider-by-day" v-if="defaultRemakr"
              ><span>{{ dataOver }}</span></span
            >
            <div class="mine" v-if="defaultRemakr">
              <div class="message">
                <div v-html="defaultRemakr"></div>
                <br />
                <div style="text-align: end; color: #917a7a; font-size: 11px">
                  {{ currentDate }}
                </div>
              </div>
            </div>
            <span
              class="divider-by-day"
              v-if="defaultRemakr == null && singleRemark"
              ><span>{{ dataOver }}</span></span
            >
            <div class="mine" v-if="defaultRemakr == null && singleRemark">
              <div class="message">
                <div v-html="singleRemark"></div>
                <br />
                <div style="text-align: end; color: #917a7a; font-size: 11px">
                  {{ currentDate }}
                </div>
              </div>
            </div>

            <template v-for="(data, index) in notesMsg">
              <div :key="index">
                <span class="divider-by-day" v-if="data && data.label"
                  ><span>{{ data.label }}</span></span
                >
                <div :class="data.color">
                  <div :class="data.color == 'your' ? 'customChat' : 'd-flex'">
                    <div>
                      <div>
                        <v-chip
                          class="mb-1 mr-1"
                          label
                          :color="
                            data && data.follow_up_status == 'follow-up-open'
                              ? 'green'
                              : 'red'
                          "
                          outlined
                          small
                          v-if="data && data.type == 'visit-notes'"
                        >
                          <span
                            v-if="
                              data && data.follow_up_status == 'follow-up-open'
                            "
                          >
                            Follow Up Open
                          </span>
                          <span
                            v-if="
                              data &&
                              data.follow_up_status == 'follow-up-closed'
                            "
                          >
                            Follow Up Close
                          </span>
                        </v-chip>
                        <v-chip
                          class="mb-1 mr-1"
                          label
                          color="red white--text"
                          small
                          v-on:click.prevent.stop="
                            copyNumber(data.reminder_barcode)
                          "
                          v-if="data && data.reminder_barcode"
                        >
                          <span v-if="data && data.reminder_barcode">{{
                            data.reminder_barcode
                          }}</span>
                        </v-chip>
                      </div>
                      <div>
                        <v-chip
                          class="mb-1 mr-1"
                          label
                          color="blue white--text"
                          outlined
                          small
                          v-if="data && data.reminder_date"
                        >
                          <span v-if="data && data.reminder_date">{{
                            formatDate(data.reminder_date)
                          }}</span>
                        </v-chip>
                      </div>
                      <v-chip
                        class="mb-1 mr-1"
                        label
                        color="black"
                        small
                        outlined
                        v-on:click.prevent.stop="copyNumber(data.user_name)"
                        v-if="data && data.user_name"
                      >
                        <span v-if="data && data.user_name">{{
                          data.user_name
                        }}</span>
                      </v-chip>
                    </div>
                    <div class="message" v-if="data">
                      <div v-if="data && data.remark">
                        {{ data.remark }}
                        <v-icon
                          v-on:click.prevent.stop="copyNumber(data.remark)"
                          class="mdi-16px ml-1"
                          >mdi-content-copy</v-icon
                        >
                        <br />
                      </div>
                      <div v-else>
                        <template
                          v-if="
                            getFileExtension(data.attachment) == 'jpg' ||
                            getFileExtension(data.attachment) == 'jpeg' ||
                            getFileExtension(data.attachment) == 'png' ||
                            getFileExtension(data.attachment) == 'jfif'
                          "
                        >
                          <v-img
                            :src="data.attachment"
                            contain
                            class="white lighten-2"
                            :max-height="150"
                            :max-width="150"
                            v-on:click="FileDownload(data.attachment)"
                          >
                          </v-img>
                        </template>
                        <template
                          v-if="getFileExtension(data.attachment) == 'pdf'"
                        >
                          <inline-svg
                            :src="$assetURL('media/mime/pdf.svg')"
                            v-on:click="FileDownload(data.attachment)"
                          />
                        </template>
                        <template
                          v-if="
                            getFileExtension(data.attachment) == 'docx' ||
                            getFileExtension(data.attachment) == 'doc'
                          "
                        >
                          <inline-svg
                            :src="$assetURL('media/mime/doc.svg')"
                            v-on:click="FileDownload(data.attachment)"
                          />
                        </template>
                        <template
                          v-if="
                            getFileExtension(data.attachment) == 'xls' ||
                            getFileExtension(data.attachment) == 'xlsx'
                          "
                        >
                          <inline-svg
                            :src="$assetURL('media/mime/xls.svg')"
                            v-on:click="FileDownload(data.attachment)"
                          />
                        </template>
                      </div>
                      <div
                        style="text-align: end; color: #917a7a; font-size: 11px"
                      >
                        {{ data.added_at }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="textarea d-flex">
            <v-textarea
              style="border: 1px solid"
              @click:append="uploadDialog()"
              append-icon="mdi-attachment"
              hide-details
              no-resize
              class="ma-0"
              v-model.trim="notesData.notes_remark"
              outlined
              rows="4"
              placeholder="Messages"
              autofocus
            >
            </v-textarea>
            <!-- <v-icon class="send-btn cursor-pointer" v-on:click="onSubmit()" v-if="notesData.notes_remark"
                >mdi-send</v-icon
              > -->
            <!-- <v-text-field
              class="text-field"
              @click:append="uploadDialog()"
              append-icon="mdi-attachment"
              v-model.trim="notesData.notes_remark"
              placeholder="Messages"
              solo
              dense
            >
            </v-text-field> -->
            <v-btn
              :disabled="!notesData.notes_remark"
              class="text-uppercase justify-center p-0"
              style="height: 58px !important; font-size: 13px; color: white"
              color="cyan"
              v-on:click="onSubmit()"
            >
              Send
            </v-btn>
          </div>
        </div>
      </v-col>
      <v-col v-if="reminderList_dialog" :md="colMd" class="message-content-new">
        <div class="drawer-wrapper custom-shadow">
          <v-flex class="px-5 d-flex border-bottom drawer-header">
            <v-icon
              class="mr-2"
              v-on:click="
                reminderList_dialog = false;
                colMd = 12;
              "
              color="cyan"
              size="30"
            >
              mdi-arrow-left
            </v-icon>
            <div class="form-title mt-1 mb-1 over-write-font">
              Reminder Listing
            </div>
            <v-spacer></v-spacer>

            <v-btn
              tile
              depressed
              color="white--text"
              class="darken-4 mt-1"
              :disabled="pageLoading || UpdateFollowUp()"
              v-on:click="manageBrandDialog = true"
              style="background-color: #00bcd4"
              >Update Status</v-btn
            >
          </v-flex>
        </div>
        <div style="height: calc(100vh - 555px) !important; overflow: auto">
          <table class="line-item-multiple-table" width="100%">
            <thead style="position: sticky">
              <tr class="custom-border-bottom">
                <td class="font-size-16 font-weight-700" width="5%">
                  Reminder
                </td>
                <td class="font-size-16 font-weight-700" width="15%">Title</td>
                <td class="font-size-16 font-weight-700" width="40%">
                  Remarks
                </td>
                <!--  <td class="font-size-16 font-weight-700" width="10%">Reminder Date</td> -->
                <td class="font-size-16 font-weight-700" width="20%">
                  Attachment
                </td>
                <td class="font-size-16 font-weight-700" width="20%">
                  Created Time
                </td>
              </tr>
            </thead>
            <tbody>
              <template>
                <tr
                  v-for="(data, index) in followup"
                  :key="index"
                  class="custom-border-bottom"
                >
                  <td class="font-size-16 font-weight-500" width="5%">
                    <v-switch
                      v-model="data.activated"
                      class="mt-0"
                      inset
                      v-on:click.prevent.stop="activeInactive(data)"
                      color="green"
                    ></v-switch>
                    <div v-on:click.prevent.stop="copyNumber(data.barcode)">
                      <Barcode :barcode="data.barcode"></Barcode>
                    </div>
                    <v-chip
                      label
                      color="red"
                      outlined
                      class="text-white p-3"
                      small
                      >{{ formatDate(data.date) }}</v-chip
                    >
                  </td>
                  <td class="font-size-16 font-weight-500" width="15%">
                    <template v-if="data && data.title">
                      <span style="white-space: break-spaces">{{
                        data.title
                      }}</span>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Title </em></template
                    >
                  </td>
                  <td class="font-size-16 font-weight-500" width="40%">
                    <template v-if="data && data.remark">
                      <span style="white-space: break-spaces">{{
                        data.remark
                      }}</span>
                    </template>
                    <template v-else
                      ><em class="text--secondary">No Remarks </em></template
                    >
                  </td>

                  <!--  <td class="font-size-16 font-weight-500" width="10%">
                <template v-if="data && data.date">
                  {{ data.date }}
                </template>
                <template v-else
                  ><em class="text--secondary">No reminder date </em></template
                >
              </td> -->

                  <td class="font-size-16 font-weight-500" width="20%">
                    <span v-if="data.attachments.length > 0">
                      <template
                        v-for="(attachmentInfo, index) in data.attachments"
                      >
                        <div class="mb-1" :key="index">
                          <template v-if="attachmentInfo">
                            <div class="d-flex align-items-center">
                              <div style="margin-left: -10px">
                                <v-btn
                                  small
                                  icon
                                  depressed
                                  color="blue darken-4"
                                  v-on:click="
                                    downloadAttachment(attachmentInfo.file.url)
                                  "
                                  ><v-icon small>mdi-download</v-icon></v-btn
                                >
                              </div>
                              <div class="ml-2">
                                <div>
                                  {{ attachmentInfo.file.name }}
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                      </template>
                    </span>
                    <em v-else class="text--secondary"> no attachments</em>
                  </td>

                  <td class="font-size-16 font-weight-500" width="20%">
                    <TableActivity
                      v-if="!lodash.isEmpty(data.added_by)"
                      :data="data"
                    >
                      <template v-slot:display_name>
                        {{ data.added_by.display_name }}
                      </template>
                    </TableActivity>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          class="mx-2 my-1 custom-grey-border"
                          color="blue-grey darken-1"
                          text-color="white"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-avatar left>
                            <v-icon>mdi-clock-outline</v-icon>
                          </v-avatar>
                          <p class="m-0 custom-nowrap-ellipsis">
                            {{ formatDateStartTime(data.added_at) }}
                          </p>
                        </v-chip>
                      </template>
                      <p class="m-0 custom-nowrap-ellipsis">
                        {{ formatDateTime(data.added_at) }}
                      </p>
                    </v-tooltip>
                  </td>
                </tr>
                <!--  <tr>
                  <td colspan="5" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no reminder at the moment.
                    </p>
                  </td>
                </tr> -->
              </template>
              <!-- <template v-else>
              <tr>
                <td :colspan="isVisitDetail ? 4 : 5">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    Sorry! No Engineer(s) Found.
                  </p>
                </td>
              </tr>
            </template> -->
            </tbody>
          </table>
        </div>

        <div class="inbox2 pa-4" v-if="false">
          <div
            class="list-items px-3 py-2 rounded mt-2 overflow-auto"
            style="background-color: rgb(227 227 227)"
            v-for="(row, index) in followup"
            :key="index"
          >
            <div class="d-flex mb-2">
              <div class="d-flex align-center">
                <v-avatar size="30">
                  <v-img
                    src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                  ></v-img>
                </v-avatar>
                <div class="ml-3 fw-600" style="font-size: 18px">
                  {{ row.added_by.display_name }}
                </div>
              </div>
              <v-spacer></v-spacer>
              <div class="date">
                <v-icon small>mdi-calendar</v-icon>
                <span v-if="row && row.date">
                  {{ row.date }}
                </span>
                <em v-else class="text-muted">no reminder date</em>
              </div>
            </div>
            <div class="ellipsis mb-2 pr-6 px-5 py-4 ml-10 white custom-border">
              <span class="fw-500 pr-2"
                ><span v-if="row && row.remark">
                  {{ row.remark }}
                </span>
                <em v-else class="text-muted">no remark</em>
              </span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <DocumentwithName
      :document-dialog="openUploadModel"
      v-on:close="openUploadModel = false"
      v-on:success="getNotesRemark()"
      :document-type="documentTypes"
      :document-id="relatedId"
      :extensive="false"
    >
    </DocumentwithName>
    <template v-if="manageBrandDialog">
      <Updatefollowup
        :c-dialog="manageBrandDialog"
        :detail="detail"
        v-on:close-dialog="manageBrandDialog = false"
        v-on:success-massage="getFollowUp"
        :visit="visit"
      ></Updatefollowup>
    </template>
    <!-- <add-dialog
			:document-dialog="openUploadModel"
			v-on:close="openUploadModel = false"
			v-on:success="getNotesRemark()"
			:related-id="relatedId"
			:chat="0"
			:related-type="relatedType"
		></add-dialog> -->
  </v-sheet>
</template>
<style scoped>
.v-dialog__content.v-dialog__content--active {
  z-index: 100 !important;
}

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 57px !important;
}

.message-content .inbox {
  height: calc(100vh - 240px) !important;
  overflow: auto;
}
.message-content-new .inbox2 {
  height: calc(100vh - 459px) !important;
  overflow: auto;
}
.wave {
  animation-name: wave-animation;
  /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s;
  /* Change to speed up or slow down */
  animation-iteration-count: infinite;
  /* Never stop waving :) */
  transform-origin: 70% 70%;
  /* Pivot around the bottom-left palm */
  display: inline-block;
  font-size: 38px;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(14deg);
  }

  /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }

  30% {
    transform: rotate(14deg);
  }

  40% {
    transform: rotate(-4deg);
  }

  50% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(0deg);
  }

  /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* For demonstration purposes only: */
body {
  font-size: 3.5em;
}

h1 {
  font-size: 0.5em;
}

/* .message-content .inbox {
	height: calc(100vh - 480px);
	overflow: auto;
} */

.v-overlay.v-overlay--active.theme--dark {
  z-index: 99 !important;
}
</style>
<script>
import { QUERY, POST, GET, PATCH } from "@/core/services/store/request.module";
import moment from "moment-timezone";
import DocumentwithName from "@/view/pages/DocumentwithName";
import Updatefollowup from "@/view/pages/job/partials/Update-follow-up.vue";
import { find } from "lodash";
// import ReminderListng from "@/view/pages/partials/Reminder-Listing";
import CommonMixin from "@/core/plugins/common-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { SuccessEventBus } from "@/core/lib/message.lib";
import { ChatRefressEventBus } from "@/core/lib/job/chats.refress.mixin";

import { last, toString } from "lodash";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";

export default {
  name: "MessageTab",
  mixins: [CommonMixin],
  props: {
    relatedId: {
      type: Number,
      default: 0,
    },
    relatedType: {
      type: String,
      default: null,
    },
    createUrl: {
      type: String,
      default: null,
    },
    documentTypes: {
      type: Number,
      default: 0,
    },
    defaultRemakr: {
      type: String,
      default: null,
    },
    defaultDate: {
      type: String,
      default: null,
    },
    getUrl: {
      type: String,
      default: null,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    /*   refress: {
      type: Boolean,
      default: false,
    }, */
    relatedUrl: {
      type: String,
      default: null,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visit: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    relatedId: {
      handler(param) {
        if (param) {
          this.getNotesRemark();
          this.dataOver = moment().format("dddd, MMMM D, YYYY, h:mm A");
        }
      },
    },
    /*  refress(param){
			if(param){
				this.getNotesRemark()
			}
		} */
  },
  data() {
    return {
      colMd: 12,
      notesData: {
        notes_remark: null,
      },
      DataSearch: null,
      notesMsg: [],
      selected_date: "all",
      reminderList_dialog: false,
      manageBrandDialog: false,
      followup: [],
      dataOver: null,
      singleRemark: null,
      /* dataLists: [
				{ value: "all", text: "All" },
				{ value: "lead-notes", text: "Lead" },
				{ value: "customer-notes", text: "Customer" },
				{ value: "task-notes", text: "Task" },
				{ value: "meeting-notes", text: "Meeting" },
			],
			dataListsCustomer: [
				{ value: "all", text: "All" },
				{ value: "customer-notes", text: "Customer" },
				{ value: "meeting-notes", text: "Meeting" },
			],
			dataTask: [
				{ value: "all", text: "All" },
				{ value: "task-notes", text: "Task" },
				{ value: "meeting-notes", text: "Meeting" },
			], */
      pageLoading: false,
      currentDate: null,
      openUploadModel: false,
    };
  },

  components: {
    /* AddDialog, */
    TableActivity,
    Barcode,
    DocumentwithName,
    Updatefollowup,
    // ReminderListng
  },
  _methods: {
    getFileExtension(path) {
      if (path) {
        return last(path.split(".")).toLowerCase();
      }
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    FileDownload(data) {
      window.open(data, "_blank");
    },
    downloadAttachment(url) {
      window.open(url, "_blank");
    },
    UpdateFollowUp() {
      if (this.followup) {
        let followupstatus = find(
          this.followup,
          (row) => row.followupdata.status == 2
        );
        if (followupstatus) {
          return true;
        }
      }
      return false;
    },
    activeInactive(data) {
      const _this = this;
      if (data.activated == false) {
        data.activated = 0;
      } else {
        data.activated = 1;
      }
      _this.$store
        .dispatch(PATCH, {
          url: `single-followup/${data.activated}/${data.id}`,
          data: {
            id: data.id,
            status: data.activated,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getFollowUp();
        });
    },

    onEsc() {
      this.resetSearch();
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.DataSearch = null;
        this.getNotesRemark();
      });
    },
    copyNumber(data) {
      /* if(type == 'phone'){
			  data = data.split("-").pop();
			} */
      navigator.clipboard.writeText(data);
      SuccessEventBus.$emit("update:success", data + " successfully coppied.");

      /* this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `<b>${data}</b> - successfully coppied.` },
			]); */
    },
    uploadDialog() {
      this.openUploadModel = true;
    },
    onSubmit() {
      if (this.notesData.notes_remark) {
        this.pageLoading = true;
        this.$store
          .dispatch(POST, {
            url: `${this.createUrl}/${this.relatedType}/${this.relatedId}`,
            data: this.notesData,
          })
          .then(() => {
            this.notesData.notes_remark = "";
            if (this.defaultRemakr == null && this.relatedUrl) {
              this.getSingleRemark();
            }

            this.getNotesRemark();
            this.$emit("refress", true);

            this.$refs.form.reset();
          })
          .catch((error) => {
            console.log({ error });
          })
          .finally(() => {
            this.pageLoading = false;
          });
      }
    },
    getFollowUp() {
      const _this = this;
      let requestUrl = "followup/" + this.visit.followup[0].id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          this.getNotesRemark();
          this.followup = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getNotesRemark(filter) {
      this.$store
        .dispatch(QUERY, {
          url: `${this.getUrl}/${this.relatedType}/${this.relatedId}`,
          data: {
            search: this.DataSearch,
            filter: filter,
          },
        })
        .then((data) => {
          this.notesMsg = data.data;
        })
        .catch((error) => {
          console.log({
            error,
          });
        });
    },
  },
  get methods() {
    return this._methods;
  },
  set methods(value) {
    this._methods = value;
  },
  computed: {
    /* btxPlaceholder() {
			if (this.relatedType == "lead-notes") {
				return this.dataLists;
			} else if (this.relatedType == "customer-notes") {
				return this.dataListsCustomer;
			} else if (this.relatedType == "task-notes") {
				return this.dataTask;
			}
			return "";
		}, */
  },
  mounted() {
    this.getNotesRemark();
    if (
      this.visit &&
      this.visit.followup &&
      this.visit.followup[0] &&
      this.visit.followup[0].id
    ) {
      this.getFollowUp();
    }

    this.currentDate = moment().format("HH:II A");
    this.dataOver = toString(this.defaultDate);
    this.dataOver = moment().format("dddd, MMMM D, YYYY, h:mm A");
    ChatRefressEventBus.$on("refress:chats", (param) => {
      if (param) {
        this.getNotesRemark();
      }
    });
  },
};
</script>
